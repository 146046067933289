<template>
  <div class="bg-primary-2-07 px-5 h-10 flex items-center">
    <div class="container mx-auto flex justify-center md:justify-between">
      <p
        v-if="shippingThreshold && shippingThresholdStatus === 'success'"
        class="font-medium text-sm"
        :class="[$i18n.locale === 'en' ? 'font-display' : 'font-display-ar']"
      >
        {{ shippingThreshold }}
      </p>

      <div class="hidden md:flex items-center text-base">
        <AppLink to="/contact" class="font-medium text-sm">
          {{ $t('customerSupport') }}
        </AppLink>
        <a
          class="ml-10 text-base"
          :class="[$i18n.locale === 'en' ? 'font-body-ar font-semibold -mt-2' : 'font-body']"
          :href="switchLocalePath($i18n.locale === 'en' ? 'ar' : 'en')"
          >{{ $t('switchLang') }}</a
        >
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useStoreConfigShippingDetails } from '~/composables/storeConfig-shippingDetails';

const { t: $t } = useI18n({
  useScope: 'local',
});
const switchLocalePath = useSwitchLocalePath();
const { shippingThreshold, status: shippingThresholdStatus } = useStoreConfigShippingDetails();
</script>

<i18n>
{
  "en": {
    "loginOrRegister": "Login or Register",
    "contactUs": "Contact us",
    "switchLang": "عربي",
    "logout":  "Logout",
    "notice": "FREE shipping on orders above 1,000 EGP",
    "customerSupport": "Customer Support",
    "greet": "Hello, {name}"
  },
  "ar": {
    "contactUs": "اتصل بنا",
    "switchLang": "English",
    "logout":  "تسجيل الخروج",
    "notice": "شحن مجاني للطلبات التي تتخطى 1,000 جنيه",
    "customerSupport": "دعم العملاء",
    "loginOrRegister": "تسجيل الدخول",
    "greet": "مرحبا، {name}"
  }
}
</i18n>
